import React, {useEffect, useState} from 'react';

import classnames from 'classnames';
import {SEO} from 'src/components/shared';
import {BasicTheme, makeTailwindBasicThemeClasses} from 'src/utils/makeTailwindBasicThemeClasses';

import {LeadForm} from './components/LeadForm';
import {RawLeadFormDataType} from './RawLeadFormData.type';

export type LeadFormStandaloneForIframeProps = {
  pageData: RawLeadFormDataType;
};

/**
 * Gatsby pages are server-side rendered, so we have to use this workaround
 * to ensure we parse the queryparams once we're really mounted in a browser
 */
const useGetThemeNameFromQueryParam = () => {
  const [themeName, setThemeName] = useState<BasicTheme | null>(null);

  useEffect(() => {
    // This code runs on the client side after the component mounts
    if (typeof window !== 'undefined') {
      const queryParams = new URLSearchParams(window.location.search);
      const themeQueryParam = queryParams.get('themeName');

      if (themeQueryParam === 'ivory' || themeQueryParam === 'darkgreen' || themeQueryParam === 'default') {
        setThemeName(themeQueryParam);
      } else {
        console.error(`Got unsupported themeQueryParam ${themeQueryParam}`);
      }
    }
  }, []);

  return themeName;
};

/**
 * For most use cases, lead forms will be embedded (using the LeadForm component) within a customPage
 *
 * Sometimes, e.g. for the current homepage and the nominations-within-the-app pages, we'll need to embed it within a page via an iframe
 *
 * This allows us to do just that - they're published at `https://chief.com/l-forms/:slug/iframe`
 */
export const LeadFormStandaloneForIframe: React.FC<LeadFormStandaloneForIframeProps> = ({pageData}) => {
  if (!pageData) {
    throw new Error(
      `Attempted to render LeadFormStandaloneForIframe with falsy pageData ${pageData} - check your utils and ssr logic!`,
    );
  }

  const themeName = useGetThemeNameFromQueryParam();
  const themeClasses = makeTailwindBasicThemeClasses(themeName);

  return (
    <>
      <SEO title={pageData.slug.current} description={pageData.slug.current} siteUrl={pageData.slug.current} />
      <div id="lead-form-standalone-wrapper-for-iframe" className={classnames(...themeClasses)}>
        <LeadForm {...pageData} />
      </div>
    </>
  );
};
