import React from 'react';

import {LeadFormStandaloneForIframe, LeadFormStandaloneForIframeProps} from './LeadFormStandaloneForIframe';

/**
 * Sometimes we'll need to embed these forms by themselves within iframes (e.g. member site embedding nominations form)
 */
const LeadFormStandaloneForIframeSSR: React.FC<{pageContext: LeadFormStandaloneForIframeProps}> = props => {
  return <LeadFormStandaloneForIframe pageData={props.pageContext.pageData} />;
};

export default LeadFormStandaloneForIframeSSR;
